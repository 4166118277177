@font-face {
  font-family: font-nav;
  src: url(../fonts/D-DIN.otf);
}

@font-face {
  font-family: font-heading;
  src: url(../fonts/Mark-Pro-Heavy.ttf);
}

@font-face {
  font-family: font-main;
  src: url(../fonts/Mark-Pro.ttf);
}

@font-face {
  font-family: font-secondary;
  src: url(../fonts/Mark-Pro-Bold.ttf);
}

/* * {
  --bg-colour: #252526;
  --nav-colour: #1e1e1e;
  --font-colour-primary: #ccd6f6;
  // --font-colour-primary: #8892b0; 
  --font-colour-secondary: white;
  // --font-colour-accent: #007acc; 
  --font-colour-accent: #ffab00;
  --textbox-colour: #2d2d30;
  --image-overlay-colour:#FFE380;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

html, body {
  min-height: 100%;
  width: 100%;
}

body {
  background-color: var(--bg-colour);
  color: white;
  font-family: font-main;
  font-family: monospace;
}


h3{
    margin: 5px 0;
}

p{
    margin: 5px 0;
}

.gray { 
    color: #918E9B;
}

.bold {
    font-weight: bold;
}

.footer {
    flex-shrink: 0;
    margin-top: auto;
    background-color: var(--nav-colour);
    text-align: center;
} */

/******************** Navbar ********************/

/* a,
a:visited,
a:hover,
a:focus{
    text-decoration: none;
    color: var(--font-colour-secondary);
}

a:hover, 
a:active { 
    text-decoration: none; 
    color:var(--font-colour-accent)
}

.nav-top {
    width: 100%;
    height: 7rem;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 999;
    top: 0px;
    box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
    background-color: var(--nav-colour);
    overflow-x: hidden;
    justify-content: space-between;
    font-family: font-nav;
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .nav-top {
    font-size: 1.5rem;
  }
  .nav-items li {
    margin: 0;
  }
}

.nav-top * {
  margin: 0rem 1rem;
}

.nav-items {
  padding: 0px;
  list-style: none;
}

.nav-items li {
    display: inline-block;
}

.logo {
    height: 75px;
    width: auto;
    margin-right: auto;
}

.logo-link {
  margin: 0;
} */

/******************** Navbar End ********************/

/******************** Navbar Animations ********************/

/* .nav-button {
  position: relative;
  padding: 0.5rem 1.5rem;
  margin-left: 2rem;
  color: var(--font-colour-secondary);
  border: 3px solid var(--font-colour-secondary);
  transition: padding 0.25s ease-in-out;
  background-color: var(--bg-colour);
}

.nav-button:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: var(--font-colour-accent);
  border-right: 3px solid var(--font-colour-secondary);
  border-bottom: 3px solid var(--font-colour-secondary);
  transition: all 0.25s ease-in-out;
}

.nav-button:hover {
  padding: 0.5rem 1.5rem; 
  cursor: pointer;  
}

.nav-button:hover:before {
  top: 0;
  left: 0;
}

.nav-link:hover{
  transform: scale(1.1);
  transition: all ease 0.25s;
}

.logo-animation:hover{ 
  animation: pulse 0.75s infinite;
  animation-timing-function: linear;   
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
} */

/******************** End of Animations ********************/

/******************** Cards ********************/

.cards-list{
  margin: auto;
  max-width: 100%;
}

.card-container {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card-container::-webkit-scrollbar {
  display: none;
}

.card-list:first-child{
  margin-left: auto;
}

.card-list:last-child{
  margin-right: auto;
}

.card{
  width: 175px;
  font-size: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.card-description{
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-img{
    width: 100%;
    height: 235px;
    object-fit: cover;
    border-radius: 3px;
}

.card-stats{
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.card-stats *{
    margin: 0 3px;
}

.card-star{
    height: 14px;
}

.cards-list > div{
    margin: 20px 0;
}

.card-star{
    margin-left: 0;
}

/******************** Cards End ********************/

/******************** Project Highlight ********************/
/*.projects{
  // padding must be same height as navbar 
  padding-top: 7rem;
}

.project-section {
  margin-bottom: 200px;
}

.project-container {
  max-width: 1000px;
  margin: 50px auto;
}

.project {
  margin: 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  align-items: center;
}

@media screen and (max-width: 768px) {
  .project {
    align-items: flex-start;
  }
}

@media screen and (max-width: 400px) {
  .project {
    margin: 0;
  }
}

.project-img, .project-description > p{
  box-shadow: 0 0 1.5rem 0.5rem rgba(0,0,0,0.5);
}

@media screen and (max-width: 600px) {
  .project-img, .project-description > p{
    box-shadow: none;
  }
}

.project-content {
  position: relative;
  z-index: 1;
  grid-column: 1/7;
  grid-row: 1/-1;
}

.project-content-flip {
  grid-column: 6 / -1;
  grid-row: 1/-1;
  text-align: right;
}

.project-content-flip ul{
  float: right;
}

@media (max-width: 600px) {
  .project-content {
    grid-column: 1/-1;
    padding: 40px;
  }
}

.project-content .project-label {
  color: var(--font-colour-accent);
}

.project-content .project-title {
  font-size: 2rem;
  margin: 10px 0 30px;
  color: var(--font-colour-secondary);
  font-family: font-heading;
}

.project-content .project-description {
  font-size: 15px;
  line-height: 1.5;
  color: var(--font-colour-primary);
}

//@media (max-width:600px){
  //* .project-content .project-description {768
    //color: var(--font-colour-secondary);
  //}
//}

.project-content .project-description p {
  background-color: var(--textbox-colour);
  padding: 20px 25px;
  border-radius: 2px;
}

@media (max-width: 600px) {
  .project-content .project-description p {
    background-color: transparent;
    padding: 20px 0;
  }
}

.project-skills {
  margin-top: 20px;
  padding-left: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.project-skills li {
  margin-right: 20px;
  list-style: none;
  float: left;
  display: block;
  color: var(--font-colour-secondary);
}

.project-img {
  position: relative;
  z-index: 0;
  background-color: var(--image-overlay-colour);
  transition: background-color 0.5s linear;
  //background: linear-gradient(0.4turn, #FFC65C, #ffaf16);
  border-radius: 3px;
  grid-column: 6/-1;
  grid-row: 1/-1;
}

.project-img-flip{
  grid-column: 1/7;
  grid-row: 1/-1;
}

@media screen and (max-width: 768px) {
  .project-img {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .project-img {
    grid-column: 1/-1;
    opacity: 0.2;
  }
}

.project-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #0a192f;
  mix-blend-mode: screen;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .project-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.project-img img {
  border-radius: 2px;
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1);
  transition: filter 0.5s linear;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: auto;
  object-fit: cover;
  vertical-align: middle;
}

.project-img:hover, .project-img:hover img{
  background-color: transparent;
  filter: none;
  transition: background-color 0.5s linear, filter 0.5s linear;
} */

/******************** End of Project Highlight ********************/

